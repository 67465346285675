.main-container {
  padding: 0 5px 5px 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px !important;
}

.form {
  min-width: 100% !important;
  background-color: #ffffff;
  padding: 15px 180px;
  display: block;
  margin-top: 0 !important;
  /* max-width: 47em !important; */
  border-radius: 0 0 10px 10px !important;
}

.button {
  width: 10rem;
  margin-left: 1rem;
  height: 2.5rem;
}

.site-page-header {
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}

/* .description {
  width: 10rem;
} */