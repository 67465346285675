.check-email-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 20px;
  }
  .mail-div {
    background-color: #00b96b; 
    border-radius: 50%; 
    padding: 15px;
  }
  .back-link {
   color: black;
   text-decoration: none;
  }
  .mail-icon {
    font-size: 40px;
    color: white;
  }