.asset-container {
  height: 100%;
  width: 100%;
  border-radius: 12px;
  padding-bottom: 50px;
}

#image {
  width: 30%;
  height: auto;
  margin-top: auto;
  margin-bottom: auto;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.scrollable-container{

}
.scrollable-container::-webkit-scrollbar {
  width: 8px; /* Adjust the width of the scrollbar */
}

.scrollable-container::-webkit-scrollbar-track {
  background: #f1f1f1; /* Light grey background for the track */
  border-radius: 10px;  /* Rounded corners for the track */
}

.scrollable-container::-webkit-scrollbar-thumb {
  background-color: #d3d3d3; /* Light grey color for the scrollbar thumb */
  border-radius: 10px;  /* Rounded corners for the thumb */
}

.scrollable-container::-webkit-scrollbar-thumb:hover {
  background-color: #a8a8a8; /* Darker grey on hover */
}
