.content {
  width: 100%;
  display: flex;
  background: #fff;
  border-radius: 10px;
}

img {
  max-width: 100%;
  height: auto;
}

input,
.ant-input-affix-wrapper,
.ant-input-group-wrapper .ant-input-number {

  border-radius: 100px !important;
  border: 1px solid #cccc;
  width: 100% !important;
  padding-left: 20px !important;
}

input::placeholder,
.ant-input-number::placeholder {
  margin-left: 10px;
}

.ant-input-number-input {
  border-radius: 100px !important;
  width: 100%;
}

.ant-input-group-addon,
.ant-input-number-group-addon {
  border-top-left-radius: 100px !important;
  border-bottom-left-radius: 100px !important;
}

.ant-input-group-addon~input {
  border-top-left-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
}

.ant-space-item {
  display: flex;
  width: 100%;
}

.ant-space-item button {
  margin: auto;
}

/* .ant-layout-sider {
  min-width: 300px !important;
  width: 300px !important;
} */

/*.ant-card:hover {
  box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  transition: box-shadow 0.3s linear;
}*/

.trigger {
  font-size: 18px;
  line-height: 40px !important;
  cursor: pointer;
  transition: color 0.3s;
  float: left !important;
  margin-top: 15px;
  margin-left: -15px;
}

.trigger:hover {
  color: #00b96b;
}

.logout-icon:hover {
  color: #00b96b;
}

.logo {
  height: 32px !important;
  margin: 16px !important;
  background: rgba(255, 255, 255, 0.3);
  z-index: 9999;
}

.site-layout .site-layout-background {
  background: #fff;
}

/* .ant-input-search-button {
  height: 50px !important;
  margin-left: -10px !important;
} */

#search {
  width: 100% !important;
  height: 100% !important;
  background: rgb(240, 240, 240);
}

.pf-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  position: relative;
}

#pencil-icon {
  position: absolute;

  background: white;
  font-size: 18px;
  left: 78%;
  bottom: 5%;
  border-radius: 100%;
  border: 1px solid #ccc;
}

.ant-switch-checked {
  background: #03ffa2 !important;
}

.login-container {
  width: 100%;
  height: 100vh;
  /* background-image: url(") ;*/

  display: flex;
  /* align-items: center; */
  justify-content: flex-end;
}

.login-container div:hover {
  box-shadow: none !important;
}

.login-container .ant-card-body {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.login-form-forgot {
  width: 100%;
  margin: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.login-form-button {
  width: 100%;
  margin: 10px;
  height: 50px !important;
  border-radius: 100px !important;
}

.search-wrapper {
  width: 30% !important;
  float: left !important;
  margin: 5px 0 0 25px !important;
}

.search-wrapper .ant-input-affix-wrapper {
  height: 40px !important;
  border-radius: 10px !important;
  width: 100% !important;
  background: rgb(240, 240, 240) !important;
}

.ant-input-affix-wrapper {
  border-radius: 10px !important;
}

.ant-form-item-control-input-content {
  flex: auto;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.no-shadow,
.no-shadow:hover {
  box-shadow: none;
}

#catalogDescription {
  height: 150px !important;
  border-radius: 10px;
  padding: 10px;
  margin: 10px 0;
}

#containter {
  display: flex;
  align-items: center !important;
  justify-content: center !important;
}

#containter input {
  width: 550px !important;
}

/* input{
  borderRadius
} */

.ant-input-affix-wrapper {
  position: relative;
  display: flex;
  width: 100%;
  min-width: 0;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  background-color: #fff;
  background-image: none;
  transition: all 0.3s;
  height: 50px !important;
  border-radius: 100px !important;
}

.new-catalog {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.new-catalog input,
.new-catalog label {
  /* margin-left:10px; */
  height: 50px;
  width: 20% !important;
  margin: 10px;
}

.new-catalog label {
  line-height: 45px;
  text-align: right;
}

.new-catalog .ant-row {
  display: flex;
  flex-direction: column;
  /* align-items: ; */
  justify-content: flex-start;
}

.new-catalog .ant-col {
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  /* justify-content: center; */
  line-height: 45px;
}

.new-catalog .new-catalog-btn {
  width: 50%;
  margin: auto;
  height: 40px;
  border-radius: 10px;
}

#nest-messages input {
  height: 50px !important;
}

button.no-radius {
  border-radius: 0px !important;
}

button {
  border-radius: 100px !important;
}

.Catalog_catalog__2g41N .ant-card {
  height: 300px;
}

.Catalog_catalog__2g41N .ant-card-body {
  height: 190px;
}

.active {
  border: 2px solid #000;
  padding: 5px;
  /* aspect-ratio: 1/; */
  border-radius: 10px;
}

.model {
  position: relative;
  overflow: hidden;
}

.model-controller {
  position: absolute;
  /* transform: rotate(90deg); */
  /* bottom:68%;
  right:45%; */
  z-index: 1;
  top: -12%;
  /* opacity: 0; */
  color: #fff;
  font-weight: bolder;
  font-size: 30 !important;
  background: transparent;
  height: 60px;
  display: flex;
  align-items: center;
  width: 100%;
  padding-inline: 12px;
  background: rgba(0, 0, 0, 0.2);
}

.model:hover .model-controller {
  top: 0;
}

.model-controller .ant-select-selector {
  background-color: transparent !important;
  color: #fff;
  /* z-index:  !important; */
}

.model-controller .ant-row {
  row-gap: 0;
  justify-content: space-between !important;
  width: 100%;
  padding: 0px;
}

#canvas {
  display: block;
  width: 100%;
  height: 100%;
  margin: 0 auto;
}

.pdp-uploader {
  width: 125px;
  height: 125px;
}

.pdp-uploader .ant-upload {
  width: 100%;
  height: 100%;
  /* float: right; */
}

.pdp_file {
  height: 200px;
  border-radius: 10px !important;
  width: 200px !important;
  margin-right: 20px !important;
  position: relative;
}

.pdp_file img {
  position: absolute;
  max-width: 100%;
  height: auto;
}

.pdp_file input {
  position: absolute;
  /* border:; */
  padding: 10px;
  width: 100%;
  height: 100%;
  border-radius: 10px !important;
}

.cards-row *:hover {
  box-shadow: none;
}

.product-list {
  display: flex;

  justify-content: center;
  align-items: center;
}

.product-list-item {
  width: 300px;
  height: 300px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 10px;
}

.product-Image {
  width: 70%;
  height: 30%;
}

.product-Image img {
  width: 100%;
}

.product-list .title {
  font-size: 20px;
  font-weight: bold;
  margin: 10px;
}

/*.site-page-header {
//   border: 1px solid rgb(235, 237, 240);
//   width:100%;
//   background-color:#fff;
//   height: 70px;
//   display: flex;
//   align-items: center;
}*/
.ant-select-selector {
  border-radius: 100px !important;
  height: 40px !important;
}

.game-icon {
  height: 100px;
  border-radius: 10px !important;
  border: 1px solid #ceceee;
  width: 100% !important;
  margin-left: 15px;
  margin-right: 20px !important;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.game-icon input {
  border-radius: 10px !important;
  opacity: 0 !important;
  position: absolute;
  height: 100%;
  cursor: pointer;
}

.game-icon::-webkit-file-upload-button {
  opacity: 0.2 !important;
}

.cards .ant-card-body {
  display: flex;
  flex-direction: row;
  width: 300px;
  align-items: center;
  justify-content: space-around;
}

.switch-game-active .ant-form-item-control-input-content {
  align-items: flex-start !important;
}

#createNewGame_gameDescription {
  border-radius: 10px !important;
}

.ant-descriptions-bordered .ant-descriptions-view,
.ant-descriptions-row,
.ant-descriptions-item-content {
  border-color: #fff !important;
}

.ant-descriptions-bordered .ant-descriptions-item-label {
  background: #fff !important;
  border: #fff !important;
}


.ant-upload .ant-upload-drag {
  height: 150px !important;
}

.nestor_table th,
.nestor_table tr {
  text-align: center !important;
}

.ant-upload-wrapper {
  width: 100% !important;
}

.details {
  width: 100%;
  background-color: #ffffff;
  padding: 10px;
}

.details>div {
  margin: auto;
}

.nestor_details_content:not(:first-child) {
  margin: 10px !important;
}

.nestor_pagination {
  color: grey;
}

.nestor_piechart {
  position: relative;
  top: -21% !important;
  width: 300px !important;
  height: auto !important;
}

.details_Card .ant-card-head {
  border: none !important
}