.comp {
  height: 43vh;
  width: 30vw;
  display: flex;
  justify-content: center;
  align-items: center;
}
/* 
.img-container {
 width: 50%;
 height: 50%;
 object-fit: contain;
}  */

/* .img{
  width: 15vw;
  height: 15vw;
  object-fit: contain;
}  */