.asset-container {
  height: 100%;
  width: 100%;
  border-radius: 12px;
  padding-bottom: 50px;
}

.cent-div{
  display: flex;
  /* justify-content: center; */
  /* align-items: center; */
  padding-left: 20px;
}
#image {
  width: 30%;
  height: auto;
  margin-top: auto;
  margin-bottom: auto;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.ant-steps {
  width: 65%;
  margin: 20px auto;
}

.asset-container.asset-container-row .ant-card-body{
  margin-top: 0;
}

#card-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

#card-timeline {
  height: auto;
  width: 100%;
  border-radius: 12px;
  max-height: 100%;
  /*overflow-y: scroll;*/
}

.ant-timeline .ant-timeline-item-last {
  padding: 0;
}


.timeline-text{
    font-weight: 200;
}

.inputAssets {
  height: 40px;
}

.asset-container .ant-card-body {
  padding: 5px 20px;
  margin-top: 20px;
}

.asset-container-main {
  height: 100%;
}

.ant-form-item-section .ant-form-item-row{
  width: 100%;
}

/* .ant-timeline-item-list .ant-timeline-item-head {
  background-color: #ccc;
} */

.versionDeployment .ant-space-item {
  width: auto;
}

.versionDeployment .ant-space-item:last-child {
  width: auto;
}

.ant-select-selector .ant-select-selection-placeholder, .ant-select-selector .ant-select-selection-item {
  /* height: 40px;
  line-height: 37px !important; */
}

.asset-card.asset-container {
	height: 100%;
}

h5.ant-typography.versionDetails-row {
  font-weight: 400;
  margin-top: 1.5em;
  display: flex;
}

.versionDetails-col {
  width: 135px;
}

.search-page-header .ant-page-header-heading-extra {
  width: 70%;
}

.search-page-header .ant-space-align-center {
  width: 100%; 

}

.search{
  margin-top: 10px;
  width: 40vw;
}

div.search Input {

  border-radius: 20px 0px 0px 20px !important;
  border: 1px solid #cccc;
  width: 100% !important;
  padding-left: 20px !important;
}

div.search button {
  border-radius: 0px 20px 20px 0px !important;
}

.search-page-header .ant-space-align-center input {
  height: 50px;  
}

.search-page-header .ant-space-align-center input:hover, .search-page-header .ant-space-align-center input:focus {
  z-index: 0; 
}

/* .search-page-header .ant-input-search-button {
  margin-left: -50px !important;
  border: none;
  background: none; 
} */

.search-page-header .ant-page-header-heading-extra .ant-space .ant-space-item:last-child {
  flex-direction: column;
  align-items: flex-end;
} 

.search-page-header .ant-space-align-center .ant-input-group-addon {
  width: auto;
}

.main-container {
  padding: 0 5px 5px 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* background-color: yellow; */
}
.form {
  width: 100%;
  margin-top: 2rem;
  max-width: 47rem;
}
.button {
  width: 10rem;
  align-self: flex-end;
  height: 2.5rem;
}

description {
  width: 10rem;
}

@keyframes rotate360 {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-360deg);
  }
}

.rotate-on-click {
  animation: rotate360 0.4s linear;
}

.secured, .unsecured {
  transform: scale(0.8);
}

.secured {
  color: #00b96b;
  font-size: 20px;margin-top: 10px;
}

.unsecured {
  font-size: 20px;margin-top: 10px;
  color: gray;
}
.label {
  color:#00000073;
  margin: 0 !important;
}
