.main-container {
  padding: 0 5px 5px 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* background-color: yellow; */
}
.form {
  width: 100%;
  margin-top: 2rem;
  max-width: 47rem;
}
.button {
  width: 10rem;
  align-self: flex-end;
  height: 2.5rem;
}

description {
  width: 10rem;
}

.update-button {
  margin-top: 25px;
}