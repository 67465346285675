.env-main {
  background-color: #f5f5f5;
  height: 100vh;
  width: 100vw;
}
.env-div {
  height: 100%;
  width: 100%;
  /* padding-top: 15px; */
}

.env-btn {
  color: #fff;
  background: #00b96b;
  box-shadow: 0 2px 0 rgba(5, 213, 88, 0.12);
}
.env-btn:hover {
  background-color: #00b96b !important;
  opacity: 0.7;
}

.label-right-align {
  text-align: right;
}

.ant-descriptions-header{
  width: 100%;
}

.env-div-top{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}
.env-div-top p{
  margin: 0;
font-size: 24px;
}



/*  */

@media (max-width: 768px) {
  .ant-descriptions-item-label {
    width: auto !important;
  }

  .ant-col-18 {
    width: 100% !important;
  }

  .ant-col-6 {
    width: 100% !important;
    display: flex;
    justify-content: center;
    margin-top: 15px;
  }
}

@media (max-width: 576px) {
  .ant-card {
    height: auto !important;
  }

  .ant-descriptions-item-label {
    justify-content: flex-start !important;
  }

  .ant-descriptions-item-content {
    text-align: left !important;
  }
}
