.asset-image {
  width: 40%;
  height: 100%;
  margin-top: auto;
  margin-bottom: auto;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.asset-image-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 300px;
}
