.go-back {
  width: 100%;
  display: flex;
  align-items: flex-start;
}
.go-back a {
  text-decoration: none;
  color: black;
}
.forgot-pass-text-div {
  padding: 30px 0;
}
.forgot-pass-text-div p {
  margin: 0;
  font-size: medium;
}
.forgot-pass-text {
  margin: 0 !important;
  padding: 10px;
}

.forgot-pass-form {
  width: 70%;
}

.forgot-pass-input:hover,
.forgot-pass-input:focus {
  border-color: #20c77c !important;
}

.forgot-pass-submit {
  padding: 20px 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #00b96b !important;
  color: white !important;
  box-shadow: 0 2px 0 rgba(5, 213, 88, 0.12);
}
.forgot-pass-submit:hover {
  opacity: 0.6;
}

@media only screen and (max-width: 426px) {
  .forgot-pass-form {
    width: 100%; 
  }
  
}