.asset-c-inputs {
  padding-top: 6px !important;
  padding-bottom: 6px !important;
  /* padding-left: 10px !important; */
  padding-right: 10px !important;
}

.ant-form-item-control {
  max-width: 37vw;
}
.max-assets {
  padding: 3px 0 !important;
}

.cardContainer {
  max-height: 500px;
  overflow-y: auto;
  padding-right: 10px;
}

.cardContainer::-webkit-scrollbar {
  width: 5px; /* Width of the vertical scrollbar */
}

.cardContainer::-webkit-scrollbar-track {
  background: transparent; /* Color of the tracking area */
}

.cardContainer::-webkit-scrollbar-thumb {
  background-color: lightgrey; /* Color of the scroll thumb */
  border-radius: 20px; /* Rounded corners on the scroll thumb */
}

.max-inputs{
  width: 100%;
  border-radius: 100px;
  padding: 3px 0px !important;
  padding-left: 2px !important;
}

