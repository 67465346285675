.roles-modal .bold {
  font-weight: 600;
}

.roles-modal .ant-table thead {
  position: sticky;
  background-color: #fafafa;
  z-index: 3;
  top: 0;
}

.roles-modal .ant-table-cell .ant-select .ant-select-selector {
  background-color: transparent !important;
  border: none !important;
}

.roles-modal .ant-table-cell .ant-select .ant-select-selector .ant-select-selection-item {
  font-weight: 600 !important;
}

.roles-modal .ant-table-cell .ant-select .ant-select-arrow {
  color: #00b96b;
}

.roles-modal .ant-select-focused .ant-select-selector {
  box-shadow: none !important;
}

.roles-modal {
  min-width: 50%;
  max-width: 100%;
}

.sameWidth {
  min-width: 79.45px;
  max-width: 79.45px;
}