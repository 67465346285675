.main-container{
    display:flex;
    justify-content:center;
    align-items: center;
    background-color:white;
    padding: 30px 0;
}

.description{
    width:30rem;
}

.modal-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
}

.clip-board-container{
    display:flex;
    flex-direction:column;
    justify-content:space-around;
    /* background-color:yellow */
}

.clip-board{
    font-size:16px;
    align-self:"center";
    color: #00b96b;
    margin: 0.6rem 0 0 15px;
    cursor: pointer;
}


.is-copied-text{
    font-size:1rem;
    height:2rem;
    text-align:center;
}

.asset-image{
    height: 12rem;
    width: 12rem;
    object-fit:contain
}