.img-compare-div {
  /* height: 50vh;
  width: 50vw; */
}

.vc-card .ant-card-body {
  padding-top: 0px !important;
}
.card-main {
  height: 80vh;
  min-width: 78vw;
  display: flex;
  flex-direction: row;
  margin-top: 0;
}
.card-left {
  height: 100%;
  width: 67%;
  background-color: #f5f5f5;
  padding: 15px;
  border-radius: 12px;
  margin-right: 5px;
}

.left-name {
  display: flex;
  justify-content: space-between;
  padding: 5px 10px;
}
.left-name p {
  font-size: large;
  /* font-weight: 500; */
  margin: 0;
}

.card-right {
  width: 29%;
  background-color: #f5f5f5;
  padding: 15px;
  border-radius: 12px;
  margin-left: 5px;
  margin-right: 10px !important;
  overflow-y: auto;
}

.right-name {
  padding: 5px 10px;
  /* top:20px */
}

.right-name p {
  font-size: large;
  font-weight: 500;
  margin: 0;
}

.h3 {
  margin-bottom: 0;
}

.scroll-rb {
  overflow-y: hidden;
  overflow-x: hidden;
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  /* min-height: 10px; */
}

.md-right-box {
  width: 100%;
  height: 35vh;
  cursor: pointer;
}

.md-right-box:not(.md-right-box:first-child) {
  margin-top: 20px;
}
.version-name {
  margin: 0;
  padding: 5px 0;
  text-align: center;
  font-size: medium;
  font-weight: 500;
  margin-top: 10px;
}

.md-right-box img {
  height: 80%;
  width: 100%;
  object-fit: scale-down;
  margin-bottom: -10px;
}

/*  */
.card-right::-webkit-scrollbar {
  width: 5px;
}

.card-right::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 10px;
}

.card-right::-webkit-scrollbar-track {
  background-color: #f0f0f0;
  border-radius: 10px;
}
