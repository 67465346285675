.forgot-main {
  background: #f8faff;
  min-height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  gap: 50px;
}
.forgot-pass-header {
  width: 100%;
  padding: 30px 50px;
  display: flex;
  justify-content: space-between;
}
.fp-div-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px;
}
.forgot-pass-container {
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  padding: 40px;
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.forgot-logo-div {
  display: flex;
}
/* .forgot-nestor-text-img {
  display: flex;
  width: 12%;
} */
.login-link {
  text-decoration: none;
}
.forgot-nestor-text-img {
  display: flex;
}

@media only screen and (max-width: 768px) {
  .forgot-nestor-text-img {
    width: 30%;
  }
}

@media only screen and (max-width: 480px) {
  .forgot-nestor-text-img {
    width: 50%; 
  }
}
@media only screen and (min-width: 769px) {
  .forgot-nestor-text-img {
    width: 12%; 
  }
}

@media only screen and (max-width: 426px) {
  .forgot-main {
    gap: 0; 
  }
  
}
@media only screen and (max-width : 320px){
  .fp-div-container{
    padding: 15px;
  }
}