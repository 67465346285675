.reset-pass-form {
  width: 100%;
}
.reset-pass-submit {
  padding: 20px 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #00b96b !important;
  color: white !important;
  box-shadow: 0 2px 0 rgba(5, 213, 88, 0.12);
}
/* #00B96B */
.reset-pass-input:hover,
.reset-pass-input:focus {
  border-color: #20c77c !important;
}
.reset-pass-submit:hover {
  opacity: 0.8;
}
