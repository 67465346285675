.main {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.gen-comp {
  height: 43vh;
  width: 30vw;
}

/* .gen-comp image{
    width: 100%;
    height: 100%;
    object-fit: cover;
} */

.gen-img {
  width: 100%;
  height: 100%;
}

.img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
