.top-card .top-card__col {
  justify-content: center;
}

.top-card .top-card__col img {
  width: 90%;
  height: 90%;
}

.top-card .top-card__col-2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.top-card .top-card__col-2 .top-card__title {
  text-align: center;
  font-weight: 200;
  font-size: xx-large;
  margin-top: 0;
}
