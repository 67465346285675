.login-page {
  background: url('/public/1.webp') no-repeat center center fixed;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.login-form-container {
  background-color: rgba(255, 255, 255, 0.8);
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.login-form {
  width: 300px;
  margin: 0 auto;
}

.login-form-forgot {
  float: right;
}

.login-form-button {
  width: 100%;
}

.login-nestor-text-div {
  display: flex;
  position:absolute;
  z-index: 9;
}

.login-nestor-text-img {
  width: 75%;
  display: flex;
}

.login-logo-div {
  max-width: 300px;
  padding-top: 10%;
  padding-bottom: 10%;
  display: flex;
  align-items:center;
  justify-content: center;
  flex-direction: column;
}

.alt-button {
  outline: solid #589636 2px;
}

.alt-button:hover {
  opacity: 0.6;
}

.form-button {
  margin: 0;
}

.or {
  margin: 15px 10px 15px 10px;

}