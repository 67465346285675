.main-container{
    display:flex;
    justify-content:center;
    margin: 2rem;
    align-items: center;
    /* background-color:yellow */
}

.description{
    width:30rem;
    
}

.modal-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
}

.clip-board-container{
    display:flex;
    flex-direction:column;
    justify-content:space-around;
    width:4.5rem;
    /* background-color:yellow */
}

.clip-board{
    font-size:1.5rem;
    align-self:"center";
    color: green;
    margin-top: 1rem;
}

.is-copied-text{
    font-size:1rem;
    height:2rem;
    text-align:center;
    /* background-color:pink; */
}

.asset-image{
     height: 12rem;
      width: 12rem;
      object-fit:contain
}