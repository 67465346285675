.signup-page {
  background: url("/public/1.webp") no-repeat center center fixed;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.signup-form-container {
  background-color: rgba(255, 255, 255, 0.8);
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.signup-form {
  width: 300px;
  margin: 0 auto;
}

.signup-form-button, .alt-button {
  width: 100%;
  height: 45px;
  margin: 10px;
}

.signup-nestor-text-div {
  display: flex;
  position: absolute;
  z-index: 9;
}

.signup-nestor-text-img {
  width: 75%;
  display: flex;
}

.signup-logo-div {
  max-width: 300px;
  padding-top: 10%;
  padding-bottom: 10%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.alt-button {
  outline: solid #589636 2px;
}

.alt-button:hover {
  opacity: 0.6;
}

.check-box {
  margin-right: 10px;
}

.TandC {
  text-align: left;
}

.check-div {
  display: flex;
  margin-bottom: 30px;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}