.env-table-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}

.env-table-top p {
  margin: 0;
  font-size: 24px;
}

.center-align {
  text-align: center;
}

.custom-link {
  color: #008000;
}

.custom-link:hover {
  color: #008000;
}