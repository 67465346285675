.reset-pass-main {
  background: #f8faff;
  min-height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.reset-pass-header {
  width: 100%;
  padding: 30px 50px;
  display: flex;
  justify-content: space-between;
}
.reset-logo-div {
  display: flex;
}

.rp-div-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px;
}
.reset-pass-container {
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  padding: 40px;
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.reset-pass-text-div {
  padding: 15px 0;
}
.reset-pass-text {
  margin: 0 !important;
  padding-bottom: 10px;
}
.reset-pass-text-div p {
  margin: 0;
  padding-bottom: 5px;
  font-size: medium;
}

.link-expired-div {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.link-expired-div p {
  padding: 10px 0px;
}
.link-expired-div a {
  text-decoration: none;
  /* color: black; */
}
.reset-nestor-text-img {
  display: flex;
}
.expiration-timer{
  font-size: small !important;
    color: grey;
}
@media only screen and (max-width: 768px) {
  .reset-nestor-text-img {
    width: 30%;
  }
}

@media only screen and (max-width: 480px) {
  .reset-nestor-text-img {
    width: 50%;
  }
}
@media only screen and (min-width: 769px) {
  .reset-nestor-text-img {
    width: 12%;
  }
}
@media only screen and (max-width: 426px) {
  .reset-pass-main {
    gap: 0; 
  }

}

@media only screen and (max-width : 320px){
  .rp-div-container{
    padding: 15px;
  }
}