.imgdiv{
    height: 30vh;
    
}
.img-main{
    height: 550px !important;
    
}

.slider__container{
    object-fit: contain !important;
    transform: none !important;
}
.slider__container img{
    height: 100% !important;
    width: 100% !important;
}